/* eslint-disable react/jsx-indent */
import React from 'react';
import Layout from '../templates/Page';
import SEO from '../templates/components/SEO';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import css from 'styled-jsx/css';
import { useForm } from 'react-hook-form';
const { styles, className } = css.resolve`
    .gatsby-image-wrapper {
        max-width: 250px;
        width: 250px;
    }
`;

const ContactPage = ({ data, location }) => {
    const { register, errors } = useForm();
    const isSuccess = location.search.match(/success/);

    return (
		<Layout>
		  <SEO title="Contact" />
		  <header className="page-header">
		    <div className="page-header__wrapper">
		      <h1 className="page-header__title">Contact</h1>
		    </div>
		  </header>
		  <div className="block block--xl-on-md">
		    <div className="wrapper wrapper--xs">
		      
		      <div className="template-content">
                  {isSuccess && (
                    <p className="success">Thank you for contacting me! I've received your message.</p>
                  )}
                  {!isSuccess && (
                  <>
                    <div className="img">
                      <div>
                        <Img className={className} fluid={data.wordpressWpMedia.localFile.childImageSharp.fluid} />
                        {styles}
                      </div>
                      <p>Hello! If you have a message for me, please fill out the form below. If you would like to work with 
                      Grace Note Literary, Ltd in any creative aspect, please also use the form below. Note that we 
                      are interested in working with underrepresented, marginalised voices. 
                      If you would like to purchase books, or if you are a retailer, please use the <Link to="/order/">ordering page</Link>.
                      </p>

                    </div>
                    <form name="contact" data-netlify="true" action="/contact/?success=true" method="POST">
                      <div className="field text">
                          <label htmlFor="name">
                              Your name <span>*</span>
                          </label>
                          <input ref={register({ required: true })} name="name" type="text" required />
                          {errors.name && <p className="error-message">Please specify your name.</p>}
                      </div>
                      <div className="field text">
                          <label htmlFor="email">
                              Your email <span>*</span>
                          </label>
                          <input name="email" type="email" required ref={register({ required: true })} />
                          {errors.email && <p className="error-message">Please provide an email address.</p>}
                      </div>
                      <div className="field textarea">
                          <label htmlFor="message">
                              Your message <span>*</span>
                          </label>
                          <textarea rows={5} name="message" ref={register({ required: true })} />
                          {errors.message && <p className="error-message">Please provide a message.</p>}
                      </div>
                      <input type="hidden" name="form-name" value="contact" />
                      <div className="actions">
                          <button type="submit" className="btn btn--primary btn--lg">Send</button>
                      </div>
                    </form>
                  </>
                  )}               
              </div>
              <div className="address">
                <p>Grace Note Literary, Ltd</p>
                <p>PO Box 22057 Khandallah Wellington 6441</p>
              </div>
		    </div>
		  </div>
          <style jsx>{`
            .field {
              margin: 2rem 0;
            }
            .img {
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              margin: 4rem 0;
              align-items: center;
            }
            .img p {
                margin: 4rem;
                max-width: 400px;
            }
          `}</style>

		</Layout>
		
    );
};

export default ContactPage;

export const pageQuery = graphql`
{
    wordpressWpMedia(title: { eq: "IMG_8700" }) {
      localFile {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;